.banner-db{ 
    background: url('../../../../public/assets/images/banner-dashboard.svg') no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
    display: flex;
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-bottom: 16px;
    -webkit-box-shadow: 0px 2px 6px 0px rgba(29,34,49,0.15);
    -moz-box-shadow: 0px 2px 6px 0px rgba(29,34,49,0.15);
    box-shadow: 0px 2px 6px 0px rgba(29,34,49,0.15);
    flex-direction: column;
    .dashboard-sliderbox{
        max-width: 600px;
        .slick-dots{
            text-align: left;
            bottom: -35px;
            li{
                margin: 0 3px;
                button:before{
                    color: #fff;
                    font-size: 12px;
                }
            }
        }
    }
    
    .left{
        h2{
            color: #fff;
            font-weight: 600;
            font-size: 22px;
            line-height: 28px;
            padding: 0;
            margin: 0 0 10px 0;
        }
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #fff;
            padding: 0;
            margin: 0;
            max-width: 520px;
            span{
                font-weight: 600;
                color: #C2D500;
            }
        }
        h3{
            font-size: 22px;
            font-weight: 600;
            line-height: 24px;
            color: #fff;
            padding: 0;
            margin: 0;
        }
        .detail{
            border-left: 2px solid #fff;
            padding-left: 24px;
            color: #fff;
            font-size: 18px;
            text-transform: uppercase;
        }
    }
    .right{
        display: flex;
        justify-content: end;
       // align-items: center;
        margin-top: 16px;
        flex-direction: column;
        .thumbnail{
            max-width: 250px;
            min-width: 250px;
            height: 60px;
            border-radius: 7px;
            display: flex;
            align-items: center;
            text-align: left;
            margin: 0 16px 16px 0;
            background-color: #CDEBF8;
            a{
                text-decoration: none;
                display: flex;
                justify-content: center;
                text-align: left;
                gap: 12px;
                align-items: center;
                padding: 0 16px;
            }
            .title{
                font-size: 14px;
                color: #071D2B;
                text-transform: uppercase;
            }
        }
    }
}

.kpi-wrapper{
    .kpi-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-height: 80px;
        background-color: #fff;
        border-radius: 8px;
        padding: 8px;
        -webkit-box-shadow: 0px 2px 6px 0px rgba(29,34,49,0.15);
        -moz-box-shadow: 0px 2px 6px 0px rgba(29,34,49,0.15);
        box-shadow: 0px 2px 6px 0px rgba(29,34,49,0.15);
        margin-bottom: 16px;
        .left{
            h3{
                text-transform: uppercase;
                color: #878A99;
                font-size: 14px;
                line-height: 16px;
                margin: 0;
                padding: 0;
            }
            h4{
                font-size: 18px;
                font-weight: 600;
                color: #495057;
                margin-top: 8px;
                margin-bottom: 0;
                padding: 0;
            }
            h5{
                font-size: 12px;
                color: #878A99;
                margin-top: 4px;
            }
        }
        .right{
            .icon-bg1{
                width: 48px;
                height: 48px;
                text-align: center;
                line-height: 48px;
                color: #39A850;
                font-size: 20px;
                background-color: #DCF6E9;
                border-radius: 8px;
            }
            .icon-bg2{
                width: 48px;
                height: 48px;
                text-align: center;
                line-height: 48px;
                color: var(--bs-primary);
                background-color: #E6E6EE;
                font-size: 20px;
                border-radius: 8px;
            }
            .icon-bg3{
                width: 48px;
                height: 48px;
                text-align: center;
                line-height: 43px;
                color: #C2D500;
                font-size: 20px;
                background-color: #FCFDF0;
                border-radius: 8px;
            }
        }
    }
}

.graph-wrapper{
    
    .content_body{
        min-height: inherit;
        margin-bottom: 24px;
    }
    .heading{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        h2{
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            color: var(--bs-primary);
            margin: 0;
            padding: 0;
        }
    }
}

.expected-return-chart{
    background-color: #F3F5F9;
    border-radius: 8px;
    padding: 15px 30px;
    .detail-box{
        border: 1px solid var(--bs-border-color);
        border-radius: 8px;
        background-color: #fff;
        padding: 10px 12px;
        margin: 0 0 8px 0;
        .chart-legend{
            width: 8px;
            height: 8px;
            color: #001E61;
            border-radius: 100%;
            display: inline-block;
            background-color: #001E61;
            margin-right: 5px;

            &.color0 {
                color: #000351;
                background-color: #000351;
            }
            &.color1 {
                color: #B30000;
                background-color: #B30000;
            }
            &.color2 {
                color: #C2D500 ;
                background-color: #C2D500;
            }
            
            
        }
        p{
            color: #1D2231;
            font-size: 14px;
            font-weight: 600;
        }
        h5{
            font-size: 16px;
            color: #2B3674;
            line-height: 22px;
            margin: 0;
            font-weight: 600;
        }
    }
}

.detail-table .table-responsive{
    scrollbar-width: auto;
   -moz-scrollbar-width: auto;
  }
  .detail-table .table-responsive::-webkit-scrollbar {
   width: 7px;
   height: 7px;
  }
  
  .detail-table .table-responsive::-webkit-scrollbar-track {
   background-color: #e5e5e5;
   border-radius: 8px;
   -webkit-border-radius: 8px;
   -moz-border-radius: 8px;
   -ms-border-radius: 8px;
   -o-border-radius: 8px;
  }
  
  .detail-table .table-responsive::-webkit-scrollbar-thumb {
   background: #999999;
   border-radius: 8px;
   -webkit-border-radius: 8px;
   -moz-border-radius: 8px;
   -ms-border-radius: 8px;
   -o-border-radius: 8px;
   transition: all 550ms;
  }

.detail-table{
    .table-responsive{
        border-radius: 8px;
        border: 1px solid #C6CDDA;
        height: 295px;
    }
   
    table{
        margin: 0;
        .sticky-top{
            background-color: #fff;
            box-shadow: 0px 1px 0px 0px rgba(29, 34, 49, 0.15);
            z-index: 99;
        }
        tr{
            th, td{
                background-color: transparent;
                color: var(--bs-body-color);
            }
            th{
                border-bottom: 2px solid #C6CDDA;
                vertical-align: middle;
            }
            td{
                border-bottom: 0;
                padding: 13px;
            }
        }
    }
}

.chat-bot{
    position: fixed;
    bottom: 30px;
    right: 25px;
    z-index: 103;
}
@include media-breakpoint-up(xs) {
    .banner-db{
        height: 450px;
        .dashboard-sliderbox {
            max-width: 320px;
        }
    }
}
@include media-breakpoint-up(sm) {
    .banner-db{
        .right{
            flex-direction: row;
        }
       
    }
}

@include media-breakpoint-up(md) {
    .banner-db{
        flex-direction: column;
        height: 270px;
        .dashboard-sliderbox {
            max-width: 530px;
        }
        .right{
            width: auto;
            flex-direction: row;
            margin-top: 15px;
            .thumbnail{
                width: 140px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .banner-db{
        padding: 50px;
        height: 310px;
        .left{
            h3{
                font-size: 32px;
                line-height: 43px;
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .banner-db{
        height: auto;
        flex-direction: row;
    }
}

.temp{
   position: relative;
}

.no-data-box{
    position: relative;
    
    &.kpi-box{
        height: 95px;
        .total-credit-bg{
            background-image: url(../../../../public/assets/images/total-credit-blur.png);
            background-repeat: no-repeat;
            height: 65px;
            background-size: cover;
            width: 100%;
        }
        .service-fee-bg{
            background-image: url(../../../../public/assets/images/service-fee-blur.png);
            background-repeat: no-repeat;
            height: 65px;
            background-size: cover;
            width: 100%;
        }
        .credit-service-blur-bg{
            background-image: url(../../../../public/assets/images/credit-service-blur.png);
            background-repeat: no-repeat;
            height: 65px;
            background-size: cover;
            width: 100%;
        }
    }

    &.graph-box{
        height: 370px;
        margin-bottom: 16px;
        .unit-processed-bg{
            background-image: url(../../../../public/assets/images/units-processed-blur.png);
            background-repeat: no-repeat;
            height: 340px;
            background-size: cover;
            width: 100%;
        }
        .clls-processed-bg{
            background-image: url(../../../../public/assets/images/clls-processed-blur.png);
            background-repeat: no-repeat;
            height: 340px;
            background-size: cover;
            width: 100%;
        }
        .expected-return-graph-bg{
            background-image: url(../../../../public/assets/images/expeted-return-graph-blur.png);
            background-repeat: no-repeat;
            height: 340px;
            background-size: cover;
            width: 100%;
        }
        .expected-return-table-bg{
            background-image: url(../../../../public/assets/images/expeted-return-table-blur.png);
            background-repeat: no-repeat;
            height: 340px;
            background-size: cover;
            width: 100%;
        }
        .no-data-overlay{
            display: flex;
            justify-content: center;
            align-items: center;
         }
         h3{
            position: absolute;
            top: 15px;
            left: 15px;
         }
    }
    .no-data-overlay{
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.8);
        border-radius: 8px;
        color: #fff;
        padding: 15px;
        font-size: 16px;
        width: 100%;
        height: 100%;
        h3{
            text-transform: uppercase;
            color: #fff;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 15px;
            padding: 0;
        }
    }
}
.order-unit-chart{
    margin-bottom: 24px;
    .content_body{
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
    }
    canvas
    {
        cursor: pointer;
    }
}
.count-schedule-chart{
    margin-bottom: 24px;
    .content_body{
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
    }
    canvas{
        cursor: pointer;
    }
}

.cradit_disposal{
    canvas{
        cursor: pointer;
    }
}