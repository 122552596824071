.footer_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 4px;
  flex-flow: column;
  
  .footer_left {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: var(--bs-primary);
  }
  .footer_right {
    display: flex;
    gap: 6px;
    align-items: center;
    color: var(--bs-primary);
    font-size: 12px;
    a {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: var(--bs-primary);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) {  }`
@include media-breakpoint-up(sm) {
}
@include media-breakpoint-up(md) {
  .footer_inner {
    flex-flow: row;
  }
}
@include media-breakpoint-up(lg) {
}
@include media-breakpoint-up(xl) {
}
@include media-breakpoint-up(xxl) {
}