@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: var(--bs-body-color);
  font-size: 13px;
}

body{
  scrollbar-width: auto;
 -moz-scrollbar-width: auto;
}
body::-webkit-scrollbar {
 width: 7px;
 height: 7px;
}

body::-webkit-scrollbar-track {
 background-color: #e5e5e5;
 border-radius: 8px;
 -webkit-border-radius: 8px;
 -moz-border-radius: 8px;
 -ms-border-radius: 8px;
 -o-border-radius: 8px;
}

body::-webkit-scrollbar-thumb {
 background: #999999;
 border-radius: 8px;
 -webkit-border-radius: 8px;
 -moz-border-radius: 8px;
 -ms-border-radius: 8px;
 -o-border-radius: 8px;
 transition: all 550ms;
}