.content_header {
  padding: 16px 0;

  .header_left {
    .content_header_title {
      color: var(--bs-primary);
      margin-bottom: 0;
      font-weight: 600;
    }
  }

  .header_right {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}


.cursor-pointer {
  cursor: pointer;
}

.content_body {
  border-radius: 8px;
  background: var(--bs-white);
  box-shadow: 0px 2px 6px 0px rgba(29, 34, 49, 0.15);
  padding: 16px;
  min-height: calc(100vh - 170px);
  min-height: calc(100dvh - 170px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form_box {
  &.form_box_bg {
    &.form_box_full {
      border-top: 2px solid rgba(29, 34, 49, 0.15);
      background-color: #f3f5f9;
      padding: 16px;
      margin: 0 -16px;

      .form-control {
        min-height: 20px;
        padding: 2px;
      }

      .react-datepicker__input-container .react-datepicker__calendar-icon {
        padding: 6px;
      }

      .error-validation {
        max-width: inherit;

        @include media-breakpoint-up(md) {
          max-width: 155px;
        }
      }
    }
  }

  .form-check {
    &.form-switch {
      height: 27px;
      display: flex;
      align-items: center;

      .form-check-input {
        height: 24px;
        width: 40px;
        margin-right: 4px;
        background-color: #fff;
        --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23000351%27/%3e%3c/svg%3e");
        border-color: #c6cdda;

        &:checked {
          background-color: #fff;
          --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23000351%27/%3e%3c/svg%3e");
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  .form-control,
  .form-select {
    background-color: var(--bs-white);
    color: #76777a;
    font-size: 13px;
    border-radius: 4px;

    &:focus {
      outline: none;
      box-shadow: none;
      border: var(--bs-border-width) solid var(--bs-border-color);
    }

    &::-ms-input-placeholder {
      /* Edge 12-18 */
      color: #76777a;
    }

    &::placeholder {
      color: #76777a;
    }
  }

  .form-label {
    color: var(--bs-body-color);
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
  }

  .input-group {

    .input-group-text {
      background: transparent;
      color: #76777a;
    }

    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
      border-left: 0;
      padding-left: 1px;
    }

    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
      border-left: 0;
      padding-left: 1px;
    }

    &:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
    &:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
    &:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
    &:not(.has-validation)>.form-floating:not(:last-child)>.form-select {
      border-right: 0;
    }
  }

  .form-control-plaintext {
    padding: 0;
    margin-top: -10px;
  }
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border-color: #2196f3 !important;
}

.ag-root-wrapper {
  border-radius: 0 !important;
  border: 0 !important;
}

.content_body_inner {
  padding-top: 16px;
}

.ag-theme-quartz,
.ag-theme-quartz-dark,
.ag-theme-quartz-auto-dark {
  --ag-header-background-color: var(--bs-white) !important;
}

.ag-body:before {
  content: "";
  width: 100%;
  position: absolute;
  height: 1px;
  top: -1px;
  left: 0;
  z-index: 1;
  background: #e6e9ee;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
}

.ag-body {
  position: relative;

  .ag-row-hover {
    background-color: var(--bs-gray-100);
  }
}


.ag-theme-quartz {
  --ag-active-color: #fff !important;
}

.ag-row {
  color: var(--bs-primary);
  border-bottom: var(--ag-row-border-style) #e6e9ee var(--ag-row-border-width);
}

.ag-ltr .ag-cell {
  text-align: left;
  color: var(--bs-body-color);
  font-size: 13px;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border-color: #2196f3 !important;
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-header-cell-comp-wrapper {
  width: 100%;
  text-transform: uppercase;
  color: var(--bs-body-color);
  font-size: 11px;
  font-weight: 700;
}

.ag-grid-wrapper {
  min-height: 350px;

  .ag-paging-panel {
    padding-top: 10px;

    @media (max-width: 767px) {
      justify-content: space-between;
      flex-wrap: wrap;
      height: auto;
      gap: 16px;

      .ag-paging-row-summary-panel {
        text-align: right;
      }

      .ag-paging-page-summary-panel {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .ag-paging-row-summary-panel {
    flex-grow: 1;
    text-align: left;
  }

  .ag-paging-page-size {
    margin: 0;
  }

  .ag-paging-page-summary-panel {
    margin: 0;
  }

  .ag-wrapper.ag-picker-field-wrapper {
    background: #1d223114;
    border: 0;
    padding: 0 5px !important;
    min-height: 23px !important;
    font-size: 14px !important;
    min-width: 70px !important;
  }

}

.ag-horizontal-left-spacer,
.ag-horizontal-right-spacer {
  overflow-x: auto !important;
}

.ag_table_footer {
  color: var(--bs-body-color);
  font-size: 13px;

  select.form-select {
    background-color: var(--bs-gray-100);
    border: 0;
  }

  .page-item {

    .page-link {
      border: 1px solid var(--bs-table-border-color);
      background-color: var(--bs-gray-100);
      color: var(--bs-body-color);
      font-size: 13px;

      &:hover {
        background-color: var(--bs-white);
      }

      &:focus {
        box-shadow: none;
      }
    }

    &.disabled {
      .page-link {
        background-color: var(--bs-gray-200);
      }

    }

    &.active {
      .page-link {
        background-color: var(--bs-primary);
        color: #fff;
      }
    }
  }

}

.ag-cell {
  .text-link {
    color: var(--bs-link-color);
    font-weight: 600;
  }
}

.custom_modal {
  .custom_modal_header {
    background: var(--bs-white);

    .custom_modal_header_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .custom_modal_header_title_left {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        color: var(--bs-primary);
      }

      .custom_modal_header_title_right {
        display: flex;
        gap: 8px;
        align-items: center;

        span {
          color: var(--Primary-Text, #3f475c);
          font-family: Lato;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
        }

        .btn {
          font-size: 21px;
          color: var(--bs-primary);
          padding: 0 8px;
        }
      }
    }
  }

  .custom_modal_body {

    // background: var(--bs-white);
    .modal-search-list {
      margin: 16px 0 0 0;
      display: flex;
      flex-flow: column;
      gap: 8px;
      max-height: 280px;
      overflow-y: auto;
      scrollbar-width: auto;
      -moz-scrollbar-width: auto;

      &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }

      &::-webkit-scrollbar-track {
        background-color: #e5e5e5;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #999999;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        transition: all 550ms;
      }


      .modal_search_list_item {
        padding: 8px;
        background: #0063a70a;
        color: var(--Primary-Text, #3f475c);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;

        input {
          &.form-check-input {
            font-size: 16px;
            margin-left: -18px;
            cursor: pointer;

            &:checked {
              background-color: #0063a7;
              border-color: #0063a7;
            }
          }
        }

        .form-check-label {
          padding-left: 8px;
          margin-top: 2px;
          width: 100%;
          cursor: pointer;
        }
      }

      .form-check {
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }

  .custom_modal_footer {
    justify-content: space-between;
    background: var(--bs-white);
  }

  &.alert_modal {
    .custom_modal_header {
      .custom_modal_header_title_right {
        .btn {
          line-height: 1;
          font-size: 30px;
        }
      }
    }

    .custom_modal_body {
      .alert_modal_body_inner {
        padding: 0 15px 15px;
        display: flex;
        justify-content: center;
        flex-flow: column;
        gap: 15px;
        align-items: center;

        .alert_modal_body_icon i {
          font-size: 50px;
        }

        .alert_modal_body_text {
          color: var(--Primary-Text, #3f475c);
          text-align: center;
          font-style: normal;
          font-weight: 400;
          line-height: 1.75;
          width: 70%;
        }
      }
    }
  }
}

.collaps_wrap {
  .collaps_inner {
    .collaps_item {
      padding-top: 16px;

      &:first-child {
        padding-top: 0px;
      }

      .collaps_item_title {
        color: var(--Primary-Color-1, #000351);
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        text-decoration: none;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        line-height: 1;
        border-bottom: 1px solid #e6e9ee;
        margin-bottom: 16px;
        border-radius: 0;

        i {
          font-size: 14px;
        }

        &:hover {
          border-bottom: 1px solid #e6e9ee;
        }

        &[aria-expanded="false"] {
          i {
            transition: all linear 0.3s;
          }
        }

        &[aria-expanded="true"] {
          i {
            transition: all linear 0.3s;
            transform: rotate(180deg);
            display: inline-block;
          }
        }
      }
    }
  }
}

.report_btn_box {
  margin-bottom: 10px;
  cursor: pointer;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #e6e9ee;
  background: rgba(255, 255, 255, 0.04);
  color: var(--bs-body-color);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 1.2;
  min-height: 60px;

  .report_btn_box_title {
    padding-right: 8px;
  }

  .report_btn_box_icon {
    color: #898a8d;
  }
}

.form_preview_box {
  padding: 8px 16px;
  border: 1px solid #c6cdda;
  border-radius: 4px;
  margin-bottom: 16px;

  .form_preivew_label {
    color: #76777a;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .form_preivew_text {
    color: var(--bs-body-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
}

.form_preview_box {
  padding: 8px;
  border: 1px solid #c6cdda;
  border-radius: 4px;
  margin-bottom: 16px;
}

.form_preivew_label {
  color: var(--Secondary-Color-4, #76777a);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.form_preivew_text {
  color: var(--Primary-Text, #3f475c);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.upload_box_wrap {
  .upload_box_inner {
    display: flex;
    flex-flow: column;
    border-radius: 8px;
    border: 1px dashed #76777a;
    background: var(--white, #fff);
    align-items: center;
    justify-content: center;
    height: 110px;
    margin: 16px 0;

    .upload_box_icon {
      color: #c6c8cf;
      font-size: 30px;
    }

    .upload_text {
      color: #76777a;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }

    .upload_text_second {
      color: #76777a;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
    }
  }
}

.custom_table_wrap {
  table.custom_table {
    width: 100%;
    margin-bottom: 24px;

    th {
      color: #3f475c;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-bottom: 1px solid #eeeeee;
      padding: 8px 0;
      text-transform: uppercase;
    }

    td {
      color: #3f475c;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 8px 0;
      border-bottom: 1px solid #eeeeee;
    }
  }
}

.info_box_wrap {
  display: flex;
  padding: 24px 20px;
  align-items: flex-start;
  border-radius: 12px;
  background: #f3f5f9;
  justify-content: space-between;

  .info_box_left {
    text-align: left;
    display: flex;
    flex-flow: column;
    gap: 8px;

    .info_box_title {
      color: var(--bs-body-color);
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 137.5% */
    }

    .info_box_main_title {
      color: var(--bs-primary);
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      /* 116.667% */
    }

    .info_box_sub_title {
      color: #76777a;
      font-family: Lato;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 169.231% */
    }
  }

  .info_box_right {
    .info_box_icon {
      display: flex;
      width: 49px;
      height: 49px;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(29, 34, 49, 0.1);
      font-size: 24px;
      color: #fff;
    }
  }
}

.section_devider {
  border-top-color: #e6e9ee;
  border-top-width: 2px;
  opacity: 1;
  margin: 24px 0;

  &.full_width {
    margin: 24px -16px;
  }
}

.btn {
  font-size: 13px;
}

.btn+.btn {
  margin-left: 8px;
}

.highlight-blue {
  color: var(--bs-primary);
}

.font-12 {
  font-size: 12px;
}


.loading {
  position: relative;
  overflow: hidden !important;
  height: 100vh;

  &::before {
    background-color: rgba(0, 0, 0, .2);
    content: "";
    width: 100%;
    height: 100vh;
    display: inline-block;
    // z-index: 11;
    position: fixed;
    z-index: 1060;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &::after {
    height: 0;
    width: 0;
    padding: 15px;
    border: 6px solid $white;
    border-right-color: #000351;
    border-radius: 22px;
    -webkit-animation: rotate 1.6s infinite linear;
    position: fixed;
    left: 50%;
    top: 50%;
    content: '';
    z-index: 1060;
    transform: translate(-50%, -50%);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
  }
}

.no-records-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.button-acc {
  position: relative;

  .recent-notify {
    width: 15px;
    height: 15px;
    background: #b30000;
    border-radius: 100%;
    position: absolute;
    text-align: center;
    top: -1px;
    right: -2px;
    color: #fff;
    font-size: 8px;
    font-weight: 700;
    line-height: 16px;
  }
}

.no-records-wrapper span {
  margin-top: 16px;
  font-weight: 600;
  font-size: 16px;
  color: #76777A;
}

.notify-list-wrapper {
  .heading {
    justify-content: space-between;
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 8px;

    h1 {
      font-size: 14px;
      font-weight: 700;
      color: var(--bs-primary);
    }

    a {
      color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
      font-size: 13px;
      text-decoration: none;
    }
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      border-bottom: 1px solid #f4f4f4;
      padding-bottom: 8px;
      margin-bottom: 8px;

      >a {
        display: flex;
        color: var(--bs-body-color);
        text-decoration: none;
        width: 100%;
        gap: 16px;

        .icon {
          background-color: #F3F5F9;
          border-radius: 4px;
          width: 32px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          flex-shrink: 0;
          position: relative;

          .recent-notify {
            position: absolute;
            top: 0;
            right: 0;
            background-color: #b30000;
            width: 8px;
            height: 8px;
            border-radius: 100%;
          }
        }

        .detail {
          flex-grow: 1;
          overflow: hidden;

          p {
            padding-bottom: 0;
            margin-bottom: 0;
            font-size: 13px;
            width: 100%;
            padding-right: 5px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            line-height: 20px;
          }

          small {
            font-size: 12px;
            color: #76777A;
          }

        }

      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .text-link {
        font-size: 13px;
        text-decoration: none;
        color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
      }
    }

  }

  .notification-list {
    // min-height: 300px;
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: auto;
    -moz-scrollbar-width: auto;
  }

  .notification-list::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  .notification-list::-webkit-scrollbar-track {
    background-color: #e5e5e5;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  .notification-list::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    transition: all 550ms;
  }
}

.notify-page {
  ul {
    li {
      >a {
        .detail {
          p {
            overflow: visible;
            text-overflow: inherit;
            white-space: inherit;
            padding-bottom: 8px;
            font-size: 14px;
          }
        }
      }
    }
  }

}

.page-not-found-wrapper {
  gap: 0;
  height: 70vh;
  flex-direction: column;

  .icon {
    color: #EAEEF6;
    font-size: 160px;
    line-height: 160px;
  }

  .description {
    h3 {
      font-size: 70px;
      font-weight: 900;
      color: var(--bs-primary);
      line-height: 74px;
    }

    h4 {
      font-size: 26px;
      font-weight: 900;
      color: var(--bs-danger);
      line-height: 30px;
    }

    p {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }
}


.error-validation {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.help-wrapper {
  .box {
    border-radius: 4px;
    border: 1px solid var(--bs-table-border-color);
    padding: 15px;
    min-height: 110px;
    margin-bottom: 15px;

    .detail {
      color: #76777A;

      .icon-pdf {
        color: var(--bs-danger);
        font-size: 30px;
      }
    }

    h3 {
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 15px;
      color: var(--bs-body-color);
    }

    a {
      color: var(--bs-body-color);
      text-decoration: none;

      &:hover {
        color: var(--bs-link-color-rgb);
      }
    }
  }
}

a.text-link {
  font-size: 13px;
  text-decoration: none;
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)) !important;

  &:hover {
    color: var(--bs-body-color);
  }
}

.report-content {
  padding: 1rem;
  background: var(--bs-body-bg);
  border-radius: 10px;
  margin-bottom: 1rem;
}

.destroyed {
  background-color: #F8D7DA;
  border-radius: 5px;
  margin: 5px 0px;
  text-align: center;
  height: 20px;
  color: #842029;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  font-size: 12px;
}

.created {
  background-color: #E4FBF0;
  border-radius: 5px;
  margin: 5px 0px;
  text-align: center;
  height: 20px;
  color: #269965;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  font-size: 12px;
}

.pending {
  background-color: #fbe39c;
  border-radius: 5px;
  margin: 5px 0px;
  text-align: center;
  height: 20px;
  color: #625736;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  font-size: 12px;
}

.inventory {
  background-color: #cff4fc;
  color: #055160;
  border-radius: 5px;
  margin: 5px 0px;
  text-align: center;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  font-size: 12px;
}

.shipped {
  background-color: #cfe2ff;
  color: #084298;
  border-radius: 5px;
  margin: 5px 0px;
  text-align: center;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  font-size: 12px;
}

.default-status {
  background-color: #6c757d;
  border-radius: 5px;
  margin: 5px 0px;
  text-align: center;
  height: 20px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  font-size: 12px;
}

.draft {
  background-color: #EDE7FB;
  border-radius: 5px;
  margin: 5px 0px;
  text-align: center;
  height: 20px;
  color: #865CE2;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  font-size: 12px;
}

.error-validation {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

option:hover {
  background-color: yellow;
}

.chart-header {
  color: #000351;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
}

.top-controls {
  .form_box {

    .form-control,
    .form-select {
      padding-top: 9px;
      padding-bottom: 10px;
    }
  }

  .btn-primary {
    padding: 10px;
  }

  .css-1nmdiq5-menu {
    z-index: 3;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  padding-right: 4.125rem !important;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  padding-right: 4.125rem !important;
}

@include media-breakpoint-up(sm) {
  .page-not-found-wrapper {
    height: auto;
  }
}


@include media-breakpoint-up(md) {
  .page-not-found-wrapper {
    flex-direction: row;
    gap: 110px;
    height: 70vh;

    .icon {
      font-size: 200px;
      line-height: 200px;
    }

    .description {
      h3 {
        font-size: 96px;
        line-height: 100px;
      }

      h4 {
        font-size: 32px;
        line-height: 36px;
      }

      p {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}



.graph-wrapper {

  .content_body {
    min-height: inherit;
    margin-bottom: 24px;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--bs-primary);
      margin: 0;
      padding: 0;
    }
  }
}

.mincardheight {
  min-height: 200px;
}

.custom-date-range {
  .form-label {
    // display: block;
    width: 100%;
    ;
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 0;
    top: 4px;
  }

  .react-datepicker__view-calendar-icon input {
    padding: 6px 25px 5px 10px;
  }

  .react-datepicker {
    border: 0;
    font-family: "Lato", sans-serif;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  }

  .react-datepicker__header {
    background-color: #fff;
  }

  .react-datepicker-popper {
    z-index: 999;
  }

  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    fill: #fff;
    color: #fff;
  }

  .react-datepicker-popper .react-datepicker__triangle {
    stroke: #ddd;
  }

  .react-datepicker__day--selected {
    background-color: #0063A7;
  }
}

.start-return {
  .custom-date-range {
    .react-datepicker__input-container .react-datepicker__calendar-icon {
      right: 0;
      top: 0px;
    }
  }
}

.was-validated .custom-date-range .react-datepicker__input-container .react-datepicker__calendar-icon {
  right: 30px;
  top: 6px;
}

.was-validated #disposalAccount,
.was-validated #pickupAcc {
  .css-13cymwt-control {
    border-color: #dc3545 !important;
  }
}

/*Select control css start------------------------------------------------*/
.css-1nmdiq5-menu {
  margin-top: 0 !important;
}

.css-t3ipsp-control,
.css-13cymwt-control {
  min-height: 40px !important;
}

.css-13cymwt-control {
  min-height: 40px;
  border-color: #c6cdda !important;
}

.css-t3ipsp-control {
  border-color: #c6cdda !important;
  box-shadow: none !important;
}

.css-t3ipsp-control:hover,
.css-13cymwt-control:hover,
.css-13cymwt-control:hover {
  border-color: #c6cdda !important;
  box-shadow: none !important;
}

.css-1xc3v61-indicatorContainer {
  color: #76777A !important;
}

.css-1u9des2-indicatorSeparator {
  width: 0 !important;
}

.css-d7l1ni-option {
  background-color: #f8f9fa !important;
}

.css-tr4s17-option {
  background-color: #0063A7 !important;
}

.css-10wo9uf-option:active {
  background-color: #f8f9fa !important;
}

.ag-theme-quartz .form_box {
  .css-1nmdiq5-menu {
    z-index: 999 !important;
  }

  .css-t3ipsp-control,
  .css-13cymwt-control {
    min-height: 30px !important;
  }

  .css-qbdosj-Input {
    margin: 0 !important;
  }

  .css-1xc3v61-indicatorContainer,
  .css-15lsz6c-indicatorContainer {
    padding: 4px !important;
  }
}

.ag_table_footer {

  .css-t3ipsp-control,
  .css-13cymwt-control {
    min-height: 30px !important;
  }

  .css-1xc3v61-indicatorContainer {
    padding: 4px !important;
  }
}

/*Select control css end------------------------------------------------*/
input:disabled {
  background-color: var(--bs-gray-100) !important;
}

.modal-30w {
  min-width: 350px !important;
  width: 100%;
  max-width: 350px !important;
}

.confirm_modal_backdrop {
  z-index: 1060;
}

.confirm_modal {
  z-index: 1060;
}

.modal-90w {
  max-width: none !important;
  width: 90%;
}

.error-estric {
  color: #B30000;
  font-size: 8px;
  position: relative;
  top: -4px;
}



.start-return-confirm {
  .ag_table_footer {
    display: none;
  }
}

#form-file-upload {
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

#label-file-upload {
  width: 100%
}

.icon-success {
  font-size: 50px;
}

.action-col {
  color: var(--bs-primary);

  a {
    color: var(--bs-primary);
  }
}

.icon-edit-gray {
  color: #D9D9D9;

  i {
    cursor: pointer;
    padding: 3px;
    margin-left: 5px;
  }

  &:hover {
    color: #000351;
  }
}

.react-checkbox-tree {
  font-size: 13px !important;

  .rct-icon {
    font-family: 'Font Awesome 6 Free' !important;
    font-weight: 900 !important;
  }

  &.rct-icons-fa4 .rct-icon-expand-close:before {
    content: '\f105' !important;
    color: var(--bs-body-color);
    font-size: 14px;
  }

  &.rct-icons-fa4 .rct-icon-expand-open:before {
    content: '\f107' !important;
    color: var(--bs-body-color);
    font-size: 14px;
  }

  &.rct-icons-fa4 .rct-icon-uncheck:before {
    font-weight: 400;
    color: #d8d9db;
  }

  .rct-checkbox {
    .rct-icon-uncheck::before {
      content: "\f0c8";
      font-weight: 400;
    }

    .rct-icon-half-check::before {
      content: "\f0c8";
      font-weight: 400;
    }
  }

  ol {
    li {
      margin-bottom: 15px;

      ol {
        li {
          margin-bottom: 0;

          .rct-icon-uncheck::before {
            content: "\f0c8";
            font-weight: 400;
          }

          .rct-node-clickable {
            font-weight: 400 !important;
          }
        }
      }

      &.rct-node-parent {
        .rct-node-clickable {
          font-weight: 600;
        }
      }

      .react-checkbox-tree label:hover,
      .rct-node-clickable:hover {
        background: none;
      }
    }
  }
}

.btn-white {
  background-color: #fff;
  border: 1px solid #E6E7EE;
  color: #76777A;

  &:hover,
  &:first-child:active,
  &.active,
  &.show {
    background-color: #fff;
    border: 1px solid #E6E7EE;
    color: #76777A;
  }
}

.credit_dollar {
  .ag_table_footer {
    display: none;
  }

  .ag-body-horizontal-scroll-viewport {
    display: none;
  }

  .total-data {
    font-weight: 600;
    text-align: right;
    padding: 10px 15px;
    background-color: #EAEEF6;
  }
}

.modal-30w {
  min-width: 350px !important;
  width: 100%;
  max-width: 350px !important;
}

.cursor_pointer {
  cursor: pointer;
}

.ag-column-drop-horizontal {
  display: none !important;
}

.icon-filter-clear {
  width: 14px;
  filter: brightness(0) saturate(100%) invert(50%) sepia(6%) saturate(172%) hue-rotate(187deg) brightness(92%) contrast(86%);

}

.btn-outline-dark {

  &:active,
  &:hover {
    color: #212529 !important;
    background-color: #f5f5f5 !important;

    .icon-filter-clear {
      filter: brightness(0) saturate(100%) invert(11%) sepia(9%) saturate(825%) hue-rotate(169deg) brightness(92%) contrast(90%) !important;
    }
  }
}

.detail-wrapper {
  margin-bottom: 24px;

  .content_body {
    min-height: inherit;
  }

  .form-group {
    margin-bottom: 15px;

    label {
      text-transform: uppercase;
      font-size: 12px;
    }

    .value {
      font-size: 14px;
      color: var(--bs-primary);
      font-weight: 600;
    }
  }
}

.top-prod-chart {
  height: 300px;
  width: 100%;

  canvas {
    width: 100% !important;
  }
}

.overstock-storage {
  margin-bottom: 24px;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--bs-primary);
      margin: 0;
      padding: 0;
    }
  }

  .content_body {
    min-height: inherit;
  }

  .card-gray {
    background-color: #F3F5F9;
    padding: 5px 30px 10px;
    border-radius: 8px;
    color: var(--bs-body-color);
    line-height: 24px;
    flex-wrap: wrap;
    gap: 10px;

    .highlight-sec {
      font-weight: 600;
      border-right: 1px solid #C6CDDA;
      padding-right: 10px;
      margin-right: 30px;
      color: var(--bs-primary);
    }

    .month-details {
      width: 80%;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}

.order-history-top {
  margin-bottom: 24px;

  .content_body {
    min-height: inherit;
  }

  label {
    font-size: 11px;
    text-transform: uppercase;
    padding-bottom: 5px;
  }

  .value {
    color: var(--bs-primary);
    font-size: 13px;
  }

  .min-width-20 {
    min-width: inherit;
  }

  .highlight-section {
    background-color: #FAFBFD;

    .header {
      padding: 8px 16px;
      border-bottom: 2px solid var(--bs-border-color);

      label {
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 500;
        padding-bottom: 0;
      }

      .value {
        color: var(--bs-primary);
        font-weight: 600;
        font-size: 13px;
      }
    }

    .first-section {
      background-color: #F3F5F9;
      padding: 8px 16px;
    }
  }

  .detail-table {
    .table-responsive {
      height: 274px;
    }
  }

  @include media-breakpoint-up(xl) {
    .min-width-20 {
      min-width: 20%;
    }
  }
}

.rcp-top {
  margin-bottom: 24px;

  .content_body {
    min-height: inherit;
  }

  .heading {
    margin-bottom: 20px;

    h2 {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--bs-primary);
      margin: 0;
      padding: 0;
    }

    p {
      margin-top: 5px;
      color: #76777A;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--bs-primary);
    margin: 0 0 10px 0;
    padding: 0;
  }

  .card-gray {
    background-color: #F3F5F9;
    padding: 10px 16px;
    border-radius: 8px;
    color: var(--bs-body-color);
    flex-wrap: wrap;
    gap: 10px;
  }

  label {
    font-size: 11px;
    text-transform: uppercase;
    padding-bottom: 5px;
  }

  .value {
    color: var(--bs-primary);
    font-size: 13px;
  }
}

.rcp-summary {
  margin-bottom: 24px;

  .content_body {
    min-height: inherit;
  }

  .heading {
    margin-bottom: 20px;

    h2 {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--bs-primary);
      margin: 0;
      padding: 0;
    }

    p {
      margin-top: 5px;
      color: #76777A;
    }
  }

  .summary-top {
    border-bottom: 1px solid var(--bs-border-color);
    margin-bottom: 20px;
  }

  .total-row {
    background-color: #F3F5F9;
    padding: 16px;
  }

  label {
    font-size: 12px;
    text-transform: uppercase;
    padding-bottom: 5px;
  }

  .value {
    color: var(--bs-primary);
    font-size: 13px;
  }
}

.rcp-payment-schedule {
  .content_body {
    min-height: inherit;
  }

  .heading {
    margin-bottom: 20px;

    h2 {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--bs-primary);
      margin: 0;
      padding: 0;
    }

    p {
      margin-top: 5px;
      color: #76777A;
    }
  }

  .detail-table {
    .table-responsive {
      height: 292px;
    }
  }
}

.drug-liquidation-top {
  margin-bottom: 24px;

  .content_body {
    min-height: inherit;
  }

  .heading {
    margin-bottom: 20px;

    h2 {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--bs-primary);
      margin: 0;
      padding: 0;
    }

    p {
      margin-top: 5px;
      color: #76777A;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--bs-primary);
    margin: 0 0 10px 0;
    padding: 0;
  }

  .card-gray {
    background-color: #F3F5F9;
    padding: 10px 16px;
    border-radius: 8px;
    color: var(--bs-body-color);
    flex-wrap: wrap;
    gap: 10px;
  }

  label {
    font-size: 11px;
    text-transform: uppercase;
    padding-bottom: 5px;
  }

  .value {
    color: var(--bs-primary);
    font-size: 13px;
  }

  .drug-liquidation-detail {
    margin-top: 50px;
  }

}

.pdf-listing-wrapper {
  h3 {
    font-size: 14px;
    font-weight: 400;
    color: var(--bs-primary);
    margin: 0 0 10px 0;
    padding: 0;
    text-transform: inherit;
  }

  .pdf-listing {
    background-color: #fff;
    border: 1px solid var(--bs-table-border-color);
    border-radius: 8px;
    padding: 16px;

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        background-color: #E6E9F2;
        border-radius: 8px;
        padding: 8px;
        color: var(--bs-primary);
        margin-bottom: 8px;

        .icon {
          color: #AA182C;
          margin-right: 8px;
          font-size: 16px;
        }

        a {
          color: var(--bs-primary);
          text-decoration: none;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.acc-listing-modal {
  .acc-checkbox-list {
    // min-height: 280px;
    max-height: 280px;
    overflow-y: auto;
    scrollbar-width: auto;
    -moz-scrollbar-width: auto;
  }

  .acc-checkbox-list::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  .acc-checkbox-list::-webkit-scrollbar-track {
    background-color: #e5e5e5;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  .acc-checkbox-list::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    transition: all 550ms;
  }

  .css-13cymwt-control,
  .css-t3ipsp-control {
    min-height: 33px !important;
  }

  .css-1xc3v61-indicatorContainer,
  .css-15lsz6c-indicatorContainer {
    padding: 2px 8px;
  }
}

.react-dropdown-tree-select .dropdown {
  width: 100%;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
  border-radius: 4px;
  border: 1px solid #c6cdda !important;
  padding: 4px 40px 4px 0.75rem !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  width: 100%;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after,
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  position: absolute;
  right: 10px;
  top: 7px;
  color: #797a7d !important;
}

.tag-item {
  margin: 1px !important;
}

.react-dropdown-tree-select .dropdown .dropdown-content ul .toggle {
  font-style: normal !important;
  width: 10px;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 14px;
  position: relative;
  top: 3px;
}

.react-dropdown-tree-select .dropdown .dropdown-content ul li.node {
  padding: 5px 10px !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger .tag {
  color: var(--bs-body-color) !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger .placeholder {
  background-color: transparent !important;
  color: var(--bs-body-color) !important;
  cursor: pointer !important;
}

.react-dropdown-tree-select .dropdown .dropdown-content .search {
  padding: 5px 10px;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  min-height: 200px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  top: 34px;
}

.react-joyride__beacon {
  >span {
    background-color: #000351 !important;
  }

  >span+span {
    border: 2px solid #000351 !important;
    background-color: transparent !important;
  }
}

.__floater__body {
  font-size: 14px !important;

  div {
    font-size: 14px !important;
  }

  [data-test-id="button-primary"] {
    background-color: var(--bs-primary) !important;
    font-size: 13px !important;
  }

  [data-test-id="button-back"] {
    color: var(--bs-primary) !important;
    font-size: 13px !important;
  }
}

.report-mgmt-grid {
  .form-check-input:checked {
    background-color: #0063a7 !important;
    border-color: #0063a7;
    font-size: 16px;
  }
}

.email-report-checked {
  .form-check-input {
    position: relative;
    top: 7px;
    font-size: 16px;
  }
}

.ag-theme-quartz .ag-standard-button {
  background-color: var(--bs-primary) !important;
  border: 1px solid var(--bs-primary) !important;
  font-size: 13px;
  color: #fff;
  padding: 4px 12px !important;

  &:hover {
    background-color: #03087d !important;
    border: 1px solid #03087d !important;
    color: #fff;
  }
}

.banner-msg-list {
  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      border-bottom: 1px solid var(--bs-gray-100);
      margin-bottom: 10px;
      padding-bottom: 10px;

      .msg-title {
        font-size: 14px;
        color: var(--bs-primary);
        margin-bottom: 5px;
        display: flex;
        align-items: start;
        justify-content: space-between;
      }

      .msg-desc {
        font-size: 13px;
        color: #76777A;
      }
    }
  }
}

.mr-5 {
  margin-right: 5px;
}

.ml-20 {
  margin-left: 20px;
}