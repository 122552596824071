.profile_inner {
  padding: 8px;
}

.profile_body {
  padding: 24px 0 0;
  .form_box .form-control-plaintext{
    font-size: 16px;
    font-weight: 600;
  }
  input:read-only {
    background-color: var(--bs-gray-100) !important;
  }
}
.profile_header {
  display: flex;
  gap: 8px;
  align-items: center;
  .profile_header_left {
    .profile_header_img {
      width: 60px;
      height: 60px;
      border-radius: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
      }
    }
  }
  .profile_header_right {
    .profile_header_name {
      color: var(--Primary-Text, #3f475c);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
    .profile_header_email {
      color: var(--Primary-Text, #3f475c);
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }
  }
}
