.navbar-toggler {
  padding: 0;
  border: 0;
}

.navbar-brand {
  margin: 0;
  padding: 0;
  line-height: 1;

  &.header_logo img {
    width: 100px;
  }
}

.user_dropdown {
  .btn-user {
    border-radius: 4px;
    border: none;
    color: var(--bs-gray-dark);
    background: #5953780d;

    &:after {
      display: none;
    }

    &:active {
      background: #5953780d;
      border-radius: 4px;
      border: none;
      outline: none;
    }
  }

  .dropdown-menu {
    background: var(--bs-white);
    border-color: var(--bs-white);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    padding: 8px 0;
    position: absolute;
  }

  .dropdown-item {
    font-size: 13px;
    font-weight: 400;
    padding: 8px 16px;
    line-height: 1.25;
  }

  .dropdown-divider {
    border-color: #f4f4f4;
    padding: 0;
    margin: 5px 15px;
  }

  .impersonate-name {
    position: relative;

    a {
      font-weight: 600;
      padding-right: 50px;

      i {
        opacity: 0;
        position: absolute;
        right: 15px;
        top: 10px;
        font-size: 14px;
        font-weight: 600;
        color: #AA182C;
      }

      &:active {
        background-color: #f8f9fa;
        color: #6d7080;
      }

      &:hover i {
        opacity: 1;
      }

    }
  }
}

.header_action {
  gap: 4px;

  .rxrd_btn {
    background-color: #b30000;
  }

  .nav-link {
    width: 36px;
    height: 36px;
    font-size: 14px;
    color: var(--bs-gray-dark);
    text-align: center;
  }
}

.header_menu {
  column-gap: 16px;
  margin: 0 15px;

  .dropdown-item {
    font-size: 13px;
    font-weight: 400;
    padding: 8px 16px;
    line-height: 1.25;

    &:hover {
      color: var(--bs-primary);
      background-color: var(--bs-dropdown-link-hover-bg);
    }
  }

  .dropdown-divider {
    border-color: #f4f4f4;
    margin: 0;
  }

  .text-link {
    color: var(--bs-link-color);

    &:hover {
      background-color: transparent;
    }
  }

  .dropdown-menu {
    background: var(--bs-white);
    border-color: var(--bs-white);
    padding: 0;
  }

  .dropdown-toggle {
    &::after {
      content: "\f078";
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      border: 0;
      margin: 0;
      padding: 0;
      line-height: 1;
      color: #595378;
      font-size: 12px;
      margin-left: 4px;
      top: 3px;
      position: relative;
    }
  }

  .nav-link {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    border-bottom: 1px solid #ddd;

    &.active {
      font-weight: 600;
    }
  }
}

.user_dropdown_menu {
  display: flex;
  gap: 16px;
  align-items: center;

  &:hover {
    background: transparent;
  }

  .user_img {
    background: #5953780d;
    padding: 10px;
    border-radius: 5px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    i {
      color: #595378;
      opacity: 0.3;
    }
  }

  .uesr_detail {
    flex-grow: 1;

    .user_name {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      color: var(--bs-primary);
      ;
    }

    .user_email {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;
      max-width: 160px;
      color: #76777a;
    }
  }
}

.btn-notify {
  position: relative;

  &:after {
    content: inherit;
  }

  .recent-notify {
    width: 20px;
    height: 20px;
    background: #b30000;
    border-radius: 100%;
    position: absolute;
    top: -1px;
    right: -2px;
    border: 2px solid var(--bs-white);
    color: #fff;
    font-size: 8px;
    font-weight: 700;
    line-height: 16px;
  }
}

.header {
  .navbar {
    background: var(--bs-white);
    box-shadow: 0px 4px 4px 0px rgba(0, 3, 81, 0.08);
    z-index: 100;
  }

  .navbar-toggler {
    &:focus {
      border: 0;
      outline: 0;
      box-shadow: none;
    }

    .navbar-toggler-icon {
      background: transparent;
      font-family: "Font Awesome 6 Free";
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;

      &:after {
        content: "\f00d";
        font-size: 24px;
      }
    }

    &.collapsed {
      .navbar-toggler-icon {
        background: transparent;
        font-family: "Font Awesome 6 Free";
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;

        &:after {
          content: "\f0c9";
        }
      }
    }
  }
}

.notify-dropdown {
  button {
    border: 0;
  }

  .dropdown-menu {
    background: var(--bs-white);
    border-color: var(--bs-white);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    padding: 0;
    position: absolute;
    min-width: 350px;
    max-width: 350px;
    right: -85px;
  }

  .dropdown-item:hover,
  .dropdown-item:focus,
  .dropdown-item:active {
    background-color: transparent;
    color: var(--bs-dropdown-link-color);
  }

}

.btn-grid {
  position: relative;

  &:after {
    display: none;
  }
}

.grid-dropdown {
  button {
    border: 0;
  }

  .icon-grid {
    width: 14px;
    position: relative;
    top: -2px;
  }

  .dropdown-menu {
    background: var(--bs-white);
    border-color: var(--bs-white);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    padding: 0;
    position: absolute;
    min-width: 310px;
    max-width: 310px;
    right: -85px;
  }

  .dropdown-item:hover,
  .dropdown-item:focus,
  .dropdown-item:active {
    background-color: transparent;
    color: var(--bs-dropdown-link-color);
  }

}

.grid-list-wrapper {
  .heading {
    justify-content: space-between;
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 8px;

    h1 {
      font-size: 14px;
      font-weight: 700;
      color: var(--bs-primary);
    }

    a {
      color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
      font-size: 13px;
      text-decoration: none;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    li {
      list-style: none;
      text-align: center;

      >a {
        color: #595378;
        text-decoration: none;

        .icon {
          background-color: #F9F9F9;
          border-radius: 8px;
          min-width: 130px;
          min-height: 65px;
          color: #7C7DA4;
          text-align: center;
          line-height: 65px;
        }

        .title {
          color: #595378;
          margin-top: 5px;
          font-size: 13px;
        }

      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .text-link {
        font-size: 13px;
        text-decoration: none;
        color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
      }
    }

  }
}

.navbar-collapse {
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  width: 100%;
  z-index: 2;
  box-shadow: 0px 2px 6px 0px rgba(29, 34, 49, 0.15);
}


// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) {  }`
@include media-breakpoint-up(sm) {}

@include media-breakpoint-up(md) {

  .user_dropdown {
    .btn-user {
      background: #5953780d;
      border-radius: 4px;
      border: none;

      &:after {
        display: none;
      }

      &:active {
        background: #5953780d;
        border-radius: 4px;
        border: none;
        outline: none;
      }
    }
  }

  .notify-dropdown {
    .dropdown-menu {
      min-width: 350px;
      max-width: 450px;
      right: 0;
    }
  }

}

@include media-breakpoint-up(lg) {
  .navbar-collapse {
    position: inherit;
    top: inherit;
    box-shadow: none;
  }

  .header_menu {
    margin: 0;

    .dropdown-menu {
      background: var(--bs-white);
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
      padding: 8px 0;
    }

    .nav-link {
      border-bottom: 0;
    }
  }
}

@include media-breakpoint-up(xl) {}

@include media-breakpoint-up(xxl) {}